import React from 'react'
import { Link } from "gatsby"
import Img from "gatsby-image"
import SortDownWhite from "../../images/chevron-down-solid-white.svg";
import htmlDecode from "html-entities-decoder";
import "./ServicesSection.css";

function ServicesSection(props) {
    const data = props.data;
    const services = props.services;
    console.log(props)

    return (
        <div className="flex flex-wrap relative container m-auto my-20">
            <div className="hm-ab-editor px-8 md:px-0" dangerouslySetInnerHTML={{ __html: data.servicesTitleEditor }}></div>
            <div className="w-full flex flex-wrap my-16">
                {services.edges.map((service, i) => (
                    <div key={`sservice-${i}`} className="w-full md:w-1/2 lg:w-1/3">
                        <Link to={`/ypiresies/${service.node.slug}/`}>
                            <div className="ss-tab-container">
                                <div className="p-8">
                                    <Img fluid={service.node.servicesAcf.featuredImage.imageFile.childImageSharp.fluid} style={{ maxHeight: "250px"}} />
                                </div>
                                <div className="px-8 pb-8 flex">
                                    <h3 className="ss-h3">{htmlDecode(service.node.title)}</h3>
                                    <img src={SortDownWhite} className="chevron-right" />
                                </div>
                                <div className="px-8 pb-8">
                                    <ul className="ss-ul">
                                        {service.node.servicesAcf.benefitsList.map((b, j) => (
                                            <li key={`benefit-${j}`}>
                                                {b.item}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default ServicesSection;

export const query = graphql`
    fragment ServicesSectionFragment on WPGraphQL_RootQueryToServiceConnection {
        edges{
            node{
                title
                slug
                servicesAcf{
                    featuredImage{
                        sourceUrl
                        altText
                        imageFile{
                            childImageSharp{
                                fluid(maxWidth: 330, quality:100){
                                    base64
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    sizes
                                }
                            }
                        }
                    }
                    benefitsList{
                        item
                    }
                }
            }
        }
    }
`

