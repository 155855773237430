import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout"
import htmlDecode from "html-entities-decoder";

import Introduction from "../components/Global/Introduction"
import ServicesSection from "../components/Services/ServicesSection"
import AppointmentTemplate from "../components/Global/AppointmentTemplate"


function ServicesTemplate(props) {
    console.log(props)
    let data = props.data.wpgraphql;

    function constructMetaData(page, currentPage) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://nextyounutrition.gr/${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://nextyounutrition.gr/${currentPage}`,
            fbType: "website",
            locale: 'el',
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    console.log(data)
    return (
        <Layout header={data.menu} footer={data.footer} appointment= {data.template.appCpt} metaData={constructMetaData(data.page, props.pageContext.currentPage)}  >
            <div>
                <section>
                    <Introduction backImg={data.page.servicesPageAcf.introductionBackgroundImage} title={htmlDecode(data.page.servicesPageAcf.introductionHeader)} />
                </section>
                <section>
                    <ServicesSection data={data.page.servicesPageAcf} services={data.services} />
                </section>
                <section>
                    <AppointmentTemplate
                        cpt={data.template.appCpt}
                        faq={data.faq.faqCpt}
                    />
                </section>
            </div>
        </Layout>
    )
}

export default ServicesTemplate;

export const pageQuery = graphql`
    query GET_SERVICE_PAGE($id: ID!) {
        wpgraphql {
            page(id: $id) {
                seo {
                    canonical
                    title
                    metaDesc
                    opengraphDescription
                    opengraphTitle
                    opengraphImage {
                        sourceUrl
                    }
                }
                breadcrumbAcf{
                    breadcrumbName
                }
			    servicesPageAcf{
                    introductionHeader
                    introductionBackgroundImage {
                        sourceUrl
                        altText
                        imageFile{
                            childImageSharp{
                                fluid(maxWidth: 2000, quality:100){
                                    base64
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    sizes
                                }  
                            }
                        }
                    }
                    servicesTitleEditor
                }
            }
            services(first: 200, where: {orderby: {field: MENU_ORDER, order: ASC}}){
                ...ServicesSectionFragment    
            }
            template(id: "cG9zdDoyMDUw"){
                appCpt {
                    ...AppointmentFragment
                }
            }
            faq: template(id: "cG9zdDoyMTA5"){
                faqCpt{
                    ...FaqFragment
                }
            }
            menu(id:"bmF2X21lbnU6MjI="){
                menuItems{
                    edges{
                        node{
                            url
                            label
                            childItems {
                                edges {
                                    node {
                                        label
                                        url
                                    }
                                }
                            }
                        }
                    }
                }
            }
            footer: template(id:"cG9zdDoyMjE5"){
                id
                title
                footerInformationTemplate{
                    address{
                        addressGoogleLink
                        addressLabel
                    }
                    hoursLabel
                    hoursTitle
                    informationTitle
                    supportEmail
                    telephoneNumber
                }
                footerAcf{
                    googlaMapsImage{
                        sourceUrl
                        altText
                        imageFile{
                            childImageSharp{
                                fluid(maxWidth: 750, quality:100){
                                    base64
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    sizes
                                }
                            }
                        }
                    }
                    city
                    callToActionText
                }
            }
        }
    }
`
